import React from "react"
import styled from "styled-components"

// Styling
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import { generateBackgroundStyles } from "../styles/stylingHelpers"
import textStyles, { fontWeights } from "../styles/textStyles"
import { fonts } from "../styles/fonts"

// Components
import ContentWrapper from "./ContentWrapper"

// Constants
import zap from "../images/zap.svg"
import rectangle from "../images/rectangle.svg"

// Types
import { ICulturalMaturity } from "../prismic-types/method"
import { Background } from "../prismic-types/homepage"
import GatsbyImage from "gatsby-image"

interface IProps extends ICulturalMaturity {
  mirrored?: boolean
  className?: string
  background: Background
}

const CulturalMaturity: React.FC<IProps> = ({
  title1,
  title2,
  text,
  desktopImg,
  className,
  mirrored,
  background,
  label,
}) => {
  return (
    <Container
      id="cultural-maturity"
      className={className}
      background={background}
    >
      <ContentWrapper>
        <InnerWrapper>
          <TextWrapper>
            {label && <Label>{label}</Label>}
            {title1 && (
              <Title1
                dangerouslySetInnerHTML={{
                  __html: title1 || "",
                }}
              />
            )}
            {title2 && (
              <Title2
                dangerouslySetInnerHTML={{
                  __html: title2 || "",
                }}
              />
            )}
            {text && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
          </TextWrapper>
          {desktopImg.url && (
            <ImageWrapper mirrored={mirrored}>
              <GatsbyImage fluid={desktopImg.url} alt={desktopImg.alt} />
            </ImageWrapper>
          )}
        </InnerWrapper>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.section<{
  color?: string
  background: Background
}>`
  position: relative;
  padding: 90px 0;
  ${({ background }) => generateBackgroundStyles(background)}
  color: ${colors.text};

  ${mq.from.S`
    padding: 104px 0 96px;
  `}

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const ImageWrapper = styled.div<{ mirrored?: boolean }>`
  width: 100%;
  height: 100%;
  order: 0;

  > div > div {
    padding-bottom: 60% !important;
  }

  img {
    margin-bottom: 0;
    width: 100%;
  }

  ${mq.from.S`
    width: 100%;
    margin-bottom: 0;
    order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  `}
`

const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Label = styled.label`
  ${textStyles.titleS};
  margin-bottom: 32px;
`

const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 0;
  margin-bottom: 42px;

  ${mq.from.XS`
    max-width: 400px;
  `}

  ${mq.from.S`
    max-width: 525px;
  `}

  ${mq.from.M`
    max-width: calc(78%);
  `}

  > h1, h2 {
    color: ${colors.main["200"]};
    ${textStyles.titleXL};
    margin-bottom: 8px;
  }

  > h3 {
    color: ${colors.main["200"]};
    ${textStyles.titleL};
    margin-bottom: 12px;
  }
`

const Title1 = styled.div`
  h2 {
    color: ${colors.main["200"]};
    ${textStyles.titleXL};
    margin-bottom: 8px;
  }
`
const Title2 = styled.div`
  h2 > {
    color: ${colors.main["200"]};
    ${textStyles.titleXL};
    margin-bottom: 8px;
  }
  position: relative;
  margin-left: auto !important;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("${rectangle}");
    background-repeat: no-repeat;
    transform: rotate(179deg);
    z-index: -1;
  }
`

const Text = styled.div`
  margin-top: 24px;
  justify-content: center;
  color: ${colors.purpleFake};
  > p {
    text-align: center;
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: ${fontWeights.regular};
    line-height: 1.5;

    ${mq.from.M`
      font-size: 22px;
      line-height: 1.33;
    `}
  }

  > h5 {
    text-align: center;
    ${textStyles.titleS};
    font-weight: 500 !important;
    ${mq.from.M`
      font-size: 22px !important;
      line-height: 30px !important;
    `}
  }

  > b {
    font-weight: ${fontWeights.bold};
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }
`

export default CulturalMaturity
