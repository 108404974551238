import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import Seo from "../components/Seo"
import BottomSection from "../components/BottomSection"
import CulturalMaturity from "../components/CulturalMaturity"
import CultureMaturityLevelsBLock from "../components/CulturalMaturityLevelsBlock"
import MethodIntro from "../components/MethodIntro"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Constants
import { getUrl } from "../constants/paths"

// Types
import { IMethodPage } from "../prismic-types/method"

interface IProps {
  content: IMethodPage
}

const Method: React.FC<IProps> = ({ content }) => {
  const seo = {
    title: content.meta.title,
    description: content.meta.description,
    lang: content.meta.lang,
    imageUrl: "",
    datePublished: content.meta.publishedDate,
    dateModified: content.meta.modifiedDate,
    slug: "method",
  }

  const [marketing1, marketing2] = content.marketingSections
  const [fancyHeading1, fancyHeading2] = content.fancyHeadings

  return (
    <>
      <Seo
        title={seo.title}
        description={seo.description}
        dateModified={seo.dateModified}
        datePublished={seo.datePublished}
        lang={seo.lang}
        slug={seo.slug}
        breadCrumbItems={[{ name: "Method", url: seo.slug }]}
      />
      <MethodIntro
        header={content.header}
        card={content.card}
        marketingSection={marketing1}
        fancyHeading={fancyHeading1}
      />
      <CulturalMaturity
        desktopImg={{
          alt: fancyHeading2.image?.alt,
          url: fancyHeading2.image?.fluid,
        }}
        mirrored
        title1={fancyHeading2.title1?.html || ""}
        title2={fancyHeading2.title2?.html || ""}
        text={fancyHeading2.description?.html || ""}
        background={{ type: "plain", color: colors.white }}
      />
      <StyledCultureMaturityLevelsBLock levels={content.levels} />
      <BottomSection
        title={marketing2.title || ""}
        text={marketing2.text || ""}
        buttonLabel={marketing2.button?.label}
        buttonLink={getUrl(marketing2.button?.link)}
        sectionId="our-story"
        titleColor={colors.purple}
        textColor={colors.purple}
        background={{
          type: "imageWithGradient",
          imageUrl: marketing2.image?.imageUrl || "",
          gradientStartColor: colors.OffWhite,
          gradientEndColor: colors.purpleDarkestDim,
          gradientStartPercentage: 17,
          gradientEndPercentage: 100,
        }}
        buttonVariant="primary"
      />
    </>
  )
}

const StyledCultureMaturityLevelsBLock = styled(CultureMaturityLevelsBLock)`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      ${colors.blueSoft} 100%
    ),
    ${colors.white};
  padding-top: 60px;

  ${mq.from.M`
  padding-top: 120px;
  `}
`

export const query = graphql`
  query Query($locale: String!) {
    raw: prismicMethodPage(lang: { eq: $locale }) {
      lang
      last_publication_date
      first_publication_date
      data {
        meta_description
        meta_title
        header_title {
          html
          text
        }
        fancy_heading {
          description {
            html
            text
          }
          title_1 {
            html
            text
          }
          title_2 {
            html
            text
          }
          image {
            alt
            url
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        card_label {
          html
          text
        }
        card_title {
          html
          text
        }
        header_description {
          html
          text
        }
        header_sub_title {
          html
          text
        }
        marketing_section {
          button_label
          button_link
          image {
            alt
            url
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          sub_title {
            html
            text
          }
          title {
            html
            text
          }
          text {
            html
            text
          }
        }
        slides {
          service_type
          label
          level
          title {
            text
          }
          text {
            html
          }
          button_label
          button_link
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const { raw } = data

  const meta = {
    title: raw.data.meta_title,
    description: raw.data.meta_description,
    publishedDate: raw.first_publication_date,
    modifiedDate: raw.last_publication_date,
    lang: raw.lang,
  }

  const header = {
    title: raw.data.header_title,
    description: raw.data.header_description,
    subTitle: raw.data.header_sub_title,
  }
  const card = {
    label: raw.data.card_label,
    title: raw.data.card_title,
  }
  const fancyHeadings = raw.data.fancy_heading.map((heading: any) => ({
    title1: heading.title_1,
    title2: heading.title_2,
    description: heading.description,
    image: heading.image,
  }))

  const marketingSections = raw.data.marketing_section.map((section: any) => ({
    subTitle: section.sub_title.html,
    title: section.title.text,
    text: section.text.html,
    button: {
      link: section.button_link,
      label: section.button_label,
    },
    image: {
      alt: section.image?.alt,
      url: section.image?.fluid,
      imageUrl: section.image?.url,
    },
  }))

  const levels = raw.data.slides.map((slide: any) => ({
    title: slide.title.text,
    label: slide.label,
    level: slide.level,
    text: slide.text.html,
    image: {
      alt: slide.image?.alt,
      url: slide.image?.fluid,
    },
  }))

  const content: IMethodPage = {
    meta,
    header,
    card,
    fancyHeadings,
    marketingSections,
    levels,
  }

  return <Method content={content} />
}
