// types
import React from "react"
import { Level } from "../prismic-types/common"
import LevelTwoTriangle from "./icons/LevelTwoTriangle"
import LevelThreeTriangle from "./icons/LevelThreeTriangle"
import LevelOneTriangle from "./icons/LevelOneTriangle"

type IProps = {
  level?: Level
  commonProps: any
}

const TriangleProgress = ({ level, commonProps }: IProps) => {
  if (!level) return null

  if (level === 1) return <LevelOneTriangle {...commonProps} />
  if (level === 2) return <LevelTwoTriangle {...commonProps} />
  if (level === 3) return <LevelThreeTriangle {...commonProps} />
  if (level === 4) return <LevelThreeTriangle {...commonProps} />
  if (level === 5) return <LevelThreeTriangle {...commonProps} />

  return null
}
export default TriangleProgress
