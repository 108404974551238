import React from "react"
import styled from "styled-components"

// Components
import ContentWrapper from "./ContentWrapper"
import TriangleProgress from "./TriangleProgress"

// Constants
import { xmlNamespace } from "../constants/svgUrl"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Types
import { ILevels } from "../prismic-types/method"
import GatsbyImage from "gatsby-image"

interface IProps extends ILevels {
  className?: string
}

const CultureMaturityLevelsBLock: React.FC<IProps> = ({
  className,
  levels,
}) => {
  const commonProps = {
    width: 48,
    height: 30,
    fill: "none",
    xmlns: xmlNamespace,
    viewBox: "0 0 48 30",
    color: colors.purpleLight,
  }
  return (
    <Container className={className}>
      <ContentWrapper>
        {levels &&
          levels.length > 0 &&
          levels.map((level) => {
            return (
              <SlideAndIconWrapper key={level.level}>
                <LeftDiv>
                  <SVG>
                    <TriangleProgress
                      level={level.level}
                      commonProps={commonProps}
                    />
                  </SVG>
                </LeftDiv>
                <Slide key={`slide-${level.title}`}>
                  <ImageWrapper>
                    {level.image.url && (
                      <GatsbyImage
                        fluid={level.image.url}
                        alt={level.image.alt}
                      />
                    )}
                  </ImageWrapper>
                  <Info>
                    <Label>{level.label}</Label>
                    <SlideTitle>{level.title}</SlideTitle>
                    {level.text && (
                      <Text dangerouslySetInnerHTML={{ __html: level.text }} />
                    )}
                  </Info>
                </Slide>
              </SlideAndIconWrapper>
            )
          })}
      </ContentWrapper>
    </Container>
  )
}

export default CultureMaturityLevelsBLock

const Container = styled.section`
  padding: 64px 0;
  background: ${colors.greyLight};

  ${mq.from.M`
    padding: 88px 0;
  `};

  ${mq.from.L`
    padding: 120px 0;
  `};
`

const Text = styled.div`
  ${textStyles.body};
  color: ${colors.main["200"]};
  margin-bottom: 24px;

  > p:last-child {
    margin-bottom: 0;
  }
`

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  margin-bottom: 80px;
  position: relative;

  ${mq.from.S`
    margin-bottom: 120px;
  `}

  ${mq.from.M`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `}
`

const Info = styled.div`
  order: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.from.M`
    width: 50%;
  `}
`

const LeftDiv = styled.div`
  position: relative;
  z-index: 1;
  ::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 5%;
    bottom: 0%;
    width: 2px;
    background-color: ${colors.purpleLight};
    z-index: 0;
  }
`

const SlideAndIconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  column-gap: 16px;
  align-items: stretch;

  /* This targets the last SlideAndIconWrapper and its child LeftDiv */
  &:last-child ${LeftDiv}::before {
    display: none;
  }

  &:last-child ${Slide} {
    margin-bottom: 0px;

    ${mq.from.S`
    margin-bottom: 0px;
  `}

    ${mq.from.M`
    margin-bottom: 0px;
  `}
  }
`

const SVG = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 50px;
  border-radius: 500px;
  background-color: ${colors.white};
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  ${mq.to.S`
    > svg {
      width: 32px;
      height: 18px;
    }
  `}

  ${mq.from.S`
    min-width: 80px;
    height: 80px;
  `}
`

const Label = styled.div`
  ${textStyles.subject};
  color: ${colors.purpleLight};
  margin-bottom: 8px;
  border-radius: 6px;
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  position: relative;

  img {
    margin-bottom: 0;
  }

  ${mq.from.S`
    margin-bottom: 0;
    order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  `}

  ${mq.from.M`
    width: 368px;
    height: 368px;
    margin-bottom: 0;
    order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  `}
`

const SlideTitle = styled.h4`
  ${textStyles.titleM};
  color: ${colors.main["100"]};
  margin-bottom: 24px;

  ${mq.from.M`
  font-size: 40px !important;
  `}
`
