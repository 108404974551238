import * as React from "react"
import { SVGProps } from "react"

const LevelThreeTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      d="M32.813 2.917a.149.149 0 0 0-.118.172l2.336 13.225a.148.148 0 0 1 0 .053L32.7 29.263a.149.149 0 0 0 .147.175H47.85A.149.149 0 0 0 48 29.29V.15a.149.149 0 0 0-.177-.146l-15.01 2.914Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.567 7.62 1.981 9.777a2.01 2.01 0 0 1 0 .798l-1.9 9.383h11.447l2.024-11.275-1.965-10.946L18.567 7.62Zm12.96 21.818a.149.149 0 0 0 .146-.122l2.332-12.986a.15.15 0 0 0 0-.053l-2.328-12.96a.149.149 0 0 0-.174-.12L16.519 6.125a.149.149 0 0 0-.117.176l2.322 11.465c.005.02.005.04 0 .06L16.409 29.26a.149.149 0 0 0 .146.178h14.972Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.86 10.88v16.698h11.968l1.984-9.675-1.919-9.358L1.86 10.88ZM.12 9.321a.149.149 0 0 0-.12.146v19.821c0 .082.067.15.149.15h15.075c.071 0 .132-.05.146-.12l2.336-11.386a.15.15 0 0 0 0-.06l-2.33-11.36a.149.149 0 0 0-.175-.117L.121 9.322Z"
      fill={props.color}
    />
  </svg>
)
export default LevelThreeTriangle
