import React from "react"
import styled from "styled-components"

// Components
import ContentWrapper from "./ContentWrapper"

// Styles
import colors from "../styles/colors"
import { fontWeights } from "../styles/textStyles"
import mq from "../styles/breakpoints"
import { fonts } from "../styles/fonts"

// Constants
import HeaderImage from "../images/method-desktop-header.png"
import MobileHeaderImage from "../images/mobile-header-image.png"
import rectangle from "../images/rectangle.svg"

// Types
import { IMarketingSection } from "../prismic-types/homepage"
import { IFancyHeading, IMethodPage } from "../prismic-types/method"
import GatsbyImage from "gatsby-image"

interface Props {
  header: IMethodPage["header"]
  card: IMethodPage["card"]
  marketingSection: IMarketingSection
  fancyHeading: IFancyHeading
}
const MethodIntro: React.FC<Props> = ({
  header,
  card,
  marketingSection,
  fancyHeading,
}) => {
  return (
    <>
      <Container id="method-intro">
        <StyledContainerWrapper>
          <InnerWrapper>
            <Label
              dangerouslySetInnerHTML={{
                __html: header.subTitle?.html || "",
              }}
            />
            <Title
              dangerouslySetInnerHTML={{
                __html: header.title?.html || "",
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: header.description?.html || "",
              }}
            />
          </InnerWrapper>
          <InnovativeSection>
            <StickyCardSection>
              <StickyCard>
                <StickyLabel
                  dangerouslySetInnerHTML={{
                    __html: card.label?.html || "",
                  }}
                />
                <StickyTitle
                  dangerouslySetInnerHTML={{
                    __html: card.title?.html || "",
                  }}
                />
              </StickyCard>
            </StickyCardSection>
            <TextWrapper>
              <Title1
                dangerouslySetInnerHTML={{
                  __html: fancyHeading.title1?.html || "",
                }}
              />
              <Title2
                dangerouslySetInnerHTML={{
                  __html: fancyHeading.title2?.html || "",
                }}
              />

              <Paragraph
                dangerouslySetInnerHTML={{
                  __html: fancyHeading.description?.html || "",
                }}
              />
            </TextWrapper>
          </InnovativeSection>
        </StyledContainerWrapper>
        <Section>
          <TextSection>
            <Label
              dangerouslySetInnerHTML={{
                __html: marketingSection?.subTitle || "",
              }}
            />
            <WeBelieveTitle as={"h1"}>{marketingSection.title}</WeBelieveTitle>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: marketingSection?.text || "",
              }}
            />
          </TextSection>
          {marketingSection.image?.url && (
            <ImageWrapper>
              <GatsbyImage
                fluid={marketingSection.image?.url}
                alt={marketingSection.image?.alt}
              />
            </ImageWrapper>
          )}
        </Section>
      </Container>
    </>
  )
}

const Container = styled.section`
  position: relative;
  background-color: ${colors.main["700"]};
  background-image: url("${HeaderImage}");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  padding-bottom: 80px;

  ${mq.to.SM`
    background-image: url("${MobileHeaderImage}");
    padding-bottom: 0px;
  `}
`

const StyledContainerWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 64px;
`

const InnerWrapper = styled.div`
  padding-top: 335px;
  max-width: 1062px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;

  ${mq.to.M`
     padding-top: 200px;
  `}

  ${mq.to.S`
     padding-top: 120px;
  `}
`

const Label = styled.div`
  > h3,
  h4 {
    font-family: ${fonts.primary};
    color: ${colors.purpleLight};
    font-size: 16px;
    line-height: 16px;
    font-weight: ${fontWeights.medium};
    margin: 0;
  }
`

const Title = styled.div`
  text-align: center !important;
  color: ${colors.main["200"]};
  max-width: 937px;
  margin: 0;
  font-weight: ${fontWeights.bold};
  letter-spacing: -2px;

  > h1 {
    font-size: 64px;
    line-height: 76px;

    ${mq.to.S`
    font-size: 40px;
    line-height: 48px;
  `}
  }
`

const Text = styled.div`
  max-width: 626px;
  color: ${colors.main["300"]};
  font-weight: ${fontWeights.regular};
  text-align: center;
  margin: 0;
  > p {
    font-size: 18px;
    line-height: 30px;

    ${mq.to.M`
    font-size: 16px;
    line-height: 24px;
  `}
  }
`
const InnovativeSection = styled.div`
  position: relative;
  max-width: 1062px;
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  z-index: 1;

  ${mq.to.SM`
  flex-direction: column;
  row-gap: 12px;
  `}
`

const StickyCardSection = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  ${mq.to.SM`
    width: 100%;
  `}
`

const StickyCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 505px;
  background-color: ${colors.main["300"]};
  border-radius: 25px;
  transform: rotate(15deg);

  ${mq.to.M`
    width: 182px;
    height: 267px;
  `}

  ${mq.to.SM`
    width: 203px;
    height: 298px;
  `}
`

const StickyLabel = styled.div`
  position: absolute;
  top: 29px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  margin: 0 0 90px 0;
  width: fit-content;
  border-radius: 52px;
  background-color: ${colors.white};
  color: ${colors.main["200"]};

  ${mq.to.M`
  line-height: 10px;
  font-size: 13px;
  margin: 0 0 40px 0;
`}

  ${mq.to.SM`
  line-height: 15px;
  font-size: 11px;
`}

  > p {
    margin-bottom: 0px !important;
  }
`

const StickyTitle = styled.div`
  position: absolute;
  top: 169px;
  left: 30px;
  right: 30px;
  color: ${colors.white};
  line-height: 58px;
  font-weight: ${fontWeights.regular};
  text-align: center;
  > h2 {
    font-size: 49.43px;

    ${mq.to.M`
    line-height: 31px;
    font-size: 26px;
  `}

    ${mq.to.SM`
    line-height: 34px;
    font-size: 29px;
  `}
  }

  ${mq.to.L`
    top: 113px !important;
  `}
`

const TextWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  order: 0;
  margin-bottom: 42px;

  ${mq.to.SM`
  margin-top: 20px;
  width: 100%;
  margin-bottom: 0;
`}
`

const Title1 = styled.div`
  position: relative;
  color: ${colors.main["200"]};
  font-weight: ${fontWeights.bold};
  margin-bottom: 0px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("${rectangle}");
    background-repeat: no-repeat;
    transform: rotate(179deg);
    z-index: -1;
  }
  > h2 {
    font-size: 48px;
    line-height: 60px;
    margin: 0 !important;
    ${mq.to.M`
    font-size: 32px;
    line-height: 44px;
  `}
  }
`
const Title2 = styled.div`
  color: ${colors.main["200"]};
  margin-bottom: 12px;
  margin-left: 40px !important;
  font-weight: ${fontWeights.bold};
  > h2 {
    line-height: 60px;
    font-size: 48px;
    margin: 0 !important;
    ${mq.to.M`
    font-size: 32px;
    line-height: 44px;
  `}
  }
`

const Paragraph = styled.div`
  color: ${colors.main["200"]};
  font-weight: ${fontWeights.regular};
  margin: 0;
  > p {
    line-height: 30px;
    font-size: 18px;

    ${mq.to.M`
    font-size: 16px;
    line-height: 24px;
  `}
  }
`

const Section = styled.div`
  padding: 60px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  ${mq.from.S`
    padding: 0 64px;
  `}

  ${mq.from.L`
    padding: 0 128px;
  `}

  ${mq.from.XL`
    max-width: 1440px;
  `}

  ${mq.to.SM`
  flex-direction: column;
  row-gap: 24px;
`}
`

const TextSection = styled.div`
  padding-left: 24px;
  border-left: 1px solid ${colors.purpleLight};
  min-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${mq.from.XSM`
  min-width: 50%;
`}
`

const WeBelieveTitle = styled.h2`
  color: ${colors.main["300"]};
  font-size: 32px;
  line-height: 40px;
  font-weight: ${fontWeights.bold};
  margin: 0;

  ${mq.to.M`
    font-size: 24px;
    line-height: 32px;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  order: 0;
  margin-bottom: 0px;

  img {
    margin-bottom: 0;
  }

  ${mq.from.XSM`
    min-width: 50%;
    margin-bottom: 40px;
  `}
`
export default MethodIntro
